import { Component, EventEmitter, Input, NgZone, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { BehaviorSubject, of, Subject } from 'rxjs'
import { GoogleCoordinates } from '../google-geocode/types'
import { DEFAULT_LAT, DEFAULT_LNG, MAP_BOUNDS_PADDING } from '../location.constants'
import { GoogleLocationMarker, MapBoundsPadding, MapState } from '../location.types'

import { GoogleMap } from '@angular/google-maps'
import { Store } from '@ngrx/store'
import { catchError, debounceTime, filter, first, map, switchMap, timeout } from 'rxjs/operators'
import { GoogleMapsConfig } from '../../../google-maps-config'
import { AAAStore } from '../../../store/root-reducer'
import { ZoneEventEmitter } from '../../../zone-event-emitter'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import events from '../../tagging/events'
import { TaggingService } from '../../tagging/tagging.service'
import { CustomMarkerComponent } from '../../ui/custom-marker/custom-marker.component'
import { MarkerDetails, USER_LOCATION_MARKER, } from '../../ui/ui.types'
import { LOCATION_TYPE, setBreakdownLocationRequest } from '../location.actions'
import { LocationUtils } from '../location.utils'
import { AbstractComponent } from "../../../shared/abstract.component";

declare let google: any

export const DEFAULT_MIN_ZOOM = 4
export const CITY_LEVEL_ZOOM = 9
export const DEFAULT_MAX_MAP_ZOOM = 19

export interface CustomMarkerInfo {
  id: string,
  location: GoogleLocationMarker,
  markerDetails: MarkerDetails,
  tabIndex: number,
  click?: () => void
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent extends AbstractComponent implements OnInit {
  @ViewChild(GoogleMap) googleMap: GoogleMap
  @ViewChildren('customMarkerComponents') customMarkerComponents: QueryList<CustomMarkerComponent>

  private _userLocation
  private shouldMove = true
  private mapDragged = false
  private mapClassInterval = null
  apiLoaded

  private coords: GoogleCoordinates = {
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
  }
  nativeMap: google.maps.Map
  MAX_MAP_ZOOM = DEFAULT_MAX_MAP_ZOOM

  defaultUserLocationData: MarkerDetails = {
    ...USER_LOCATION_MARKER,
  }

  isDraggingMap$ = new BehaviorSubject(false)
  centralizeMap$ = new Subject<GoogleCoordinates>()
  centerChanged$ = new Subject();
  dragEnd$ = new Subject();
  dragStart$ = new Subject();

  @Input() zoomLevel = DEFAULT_MIN_ZOOM
  @Input() showGpsLocation = false
  @Input() centerMarker: MarkerDetails
  @Input() customMarkers: CustomMarkerInfo[]
  @Input() fitMarkers = false
  @Input() fitMapBounds = true
  @Input() tabIndexPosition = 0
  @Input() static = false
  @Input() showActionButtons = false
  @Input() isLoading = false
  @Input() enablePOICLick = false
  @Input() mapBoundsPadding: MapBoundsPadding

  @Input() get userLocation() {
    return this._userLocation
  }
  set userLocation(value) {
    this._userLocation = value
  }

  @Input() get center(): GoogleCoordinates {
    return this.coords
  }
  set center(coords) {
    if (this.mapDragged) {
      this.centralizeMap$.next(coords)
    } else {
      this.onCentralizeMap(coords);
    }
  }

  @Input() displayZoomAction = true
  @Input() displayLocateMeAction = false
  @Input() hasLocationAccess = false
  @Input() hasDeniedGpsAccess = false
  @Input() isValidBreakdownLocation = false
  @Input() mapCenter: GoogleLocationMarker
  @Output() markerClicked = new EventEmitter()
  @Output() locationChanged = new EventEmitter()
  @Output() mapDrag: EventEmitter<MapState>
  @Output() mapClick: EventEmitter<GoogleCoordinates>
  @Output() adjustLocationClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() adjustPinClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() searchAreaClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() useCurrentLocation = new EventEmitter()
  @Output() onShopDetailsClose: EventEmitter<void> = new EventEmitter()

  refreshCustomMarkers() {
    if (this.nativeMap && this.mapCenter && !this.isLoading && this.customMarkers) { // TODO remove mapCenter when all pages (breakdown and tow-to) are refactored. this.mapCenter will be always present.
      this.customMarkerComponents?.forEach((customElements) => customElements.refreshMarker())
      this.handleMapFocus(this.mapCenter)
      this.refreshFitMarkers()
    }
  }

  refreshFitMarkers() {
    if (this.fitMarkers) {
      const bounds = new google.maps.LatLngBounds()
      this.customMarkers.forEach((marker) => {
        if (
          !isNaN(marker?.location.lat) &&
          !isNaN(marker?.location.lng)
        ) {
          const coords = new google.maps.LatLng({
            lat: +marker.location.lat,
            lng: +marker.location.lng,
          })
          bounds.extend(coords)
        }
      })

      const mapBoundsPadding = {
        ...MAP_BOUNDS_PADDING,
        ...this.mapBoundsPadding
      }

      this.nativeMap.fitBounds(bounds, mapBoundsPadding)
      this.nativeMap.panToBounds(bounds, mapBoundsPadding)
    }
  }

  ngOnChanges(changes) {
    if (changes.customMarkers) {
      this.refreshCustomMarkers()
    }
  }

  get currentMapZoom() {
    if (this.nativeMap) {
      return this.nativeMap.getZoom()
    }
  }

  constructor(
    private store$: Store<AAAStore>,
    private ngZone: NgZone,
    public taggingService: TaggingService,
    public adobeEventService: AdobeEventService,
    public locationUtils: LocationUtils,
    public googleMapsConfig: GoogleMapsConfig,
  ) {
    super()
    this.mapDrag = new ZoneEventEmitter<MapState>(ngZone)
    this.mapClick = new ZoneEventEmitter<GoogleCoordinates>(ngZone)
  }

  ngOnInit(): void {
    const TIMEOUT_GET_COORDINATES = 2000;
    const TIME_BETWEEN_MAP_DRAG = 300;
    this.subscriptions.push(
      this.googleMapsConfig.obsCurrentApiStatus.subscribe(status => {
        this.apiLoaded = status.valueOf()
      }),
      this.dragStart$.subscribe(() => this.isDraggingMap$.next(true)),
      this.centralizeMap$.pipe(
        switchMap(coords => this.isDraggingMap$.pipe(
          debounceTime(TIMEOUT_GET_COORDINATES),
          filter(isDragging => !isDragging),
          first(),
          map(() => coords)
        ))
      ).subscribe((coords) => this.onCentralizeMap(coords)),
      this.dragEnd$.pipe(
        switchMap(() => this.centerChanged$.pipe(
          debounceTime(TIME_BETWEEN_MAP_DRAG),
          first(),
          timeout(TIME_BETWEEN_MAP_DRAG),
          catchError(() => of('')),
        ))
      ).subscribe(() => this.onDragEnd())
    )
  }

  onDragEnd = () => {
    this.isDraggingMap$.next(false)
    if (this.mapDrag) {
      this.handleDragEndEvent()
    }
  }

  onCentralizeMap = (coords: GoogleCoordinates) => {
    this.coords = (coords?.lat && coords?.lng) ? coords : this.coords
    this.handleDebouncedCenter(coords)
  }

  _clickListener = (e: google.maps.MapMouseEvent) => {
    if ((e as google.maps.IconMouseEvent).placeId && !this.enablePOICLick) {
      e.stop()
    }
  }

  _dragStartListener = () => {
    this.dragStart$.next()
  }

  _dragEndListener = () => {
    this.dragEnd$.next()
  }

  _centerChangedListener = () => {
    this.centerChanged$.next()
  }

  bootstrapMap(e: google.maps.Map) {
    this.nativeMap = e
    this.refreshCustomMarkers()
    this.nativeMap.addListener('dragstart', this._dragStartListener)
    this.nativeMap.addListener('dragend', this._dragEndListener)
    this.nativeMap.addListener('center_changed', this._centerChangedListener)
    this.nativeMap.addListener('click', this._clickListener)

    this.mapClassInterval = setInterval(() => {
      const _labels = document.getElementsByClassName('gmnoprint')
      if (_labels.length) {
        clearInterval(this.mapClassInterval)
        this.handleAccessibilityElement(_labels)
      }
    }, 300)
  }

  private handleDragEndEvent() {
    this.mapDragged = true
    const coords = this.nativeMap?.getCenter()
    if (!this.shouldMove || !coords) {
      return
    }

    const lat = coords.lat()
    const lng = coords.lng()

    if (lat === 0 || lng === 0) {
      return
    }
    const mapState = this.locationUtils.convertToMapState(this.nativeMap)
    if (mapState) {
      this.mapDrag.emit(mapState)
    }
  }

  handleCustomMarkerClick(customMarker: CustomMarkerInfo) {
    if (!customMarker.click) {
      return
    }
    customMarker.click()
    let tabIndex = customMarker.tabIndex
    this.customMarkers
      .filter(marker => marker.markerDetails.active)
      .forEach(marker => {
        marker.markerDetails.active = false
        tabIndex = marker.tabIndex > tabIndex ? marker.tabIndex : tabIndex
      })
    customMarker.markerDetails.active = true
    customMarker.tabIndex = tabIndex + 1
  }

  private fitBounds() {
    if(this.currentMapZoom !== DEFAULT_MIN_ZOOM) {
      return
    }
  }

  private handleMapFocus(mapCenter) {
    const center = new google.maps.LatLng(mapCenter.lat, mapCenter.lng)
    this.nativeMap.panTo(center)
    this.fitBounds()
  }

  zoomIn() {
    this.zoomLevel = this.nativeMap.getZoom() + 1
  }

  zoomOut() {
    this.zoomLevel = this.nativeMap.getZoom() - 1
  }

  handleStop(stop: boolean) {
    this.shouldMove = !stop
    const currentZoom = this.nativeMap.getZoom()
    this.nativeMap.setOptions({
      ...(stop ? { gestureHandling: 'none' } : {}),
      maxZoom: stop ? currentZoom : DEFAULT_MAX_MAP_ZOOM,
      minZoom: stop ? currentZoom : DEFAULT_MIN_ZOOM,
    })
  }

  public handleDebouncedCenter(coords) {
    this.coords = (coords?.lat && coords?.lng) ? coords : this.coords
  }

  private centerToCoords(coords: GoogleCoordinates) {
    if (!coords.lat || !coords.lng || !this.nativeMap) {
      return
    }
    const center = new google.maps.LatLng(coords.lat, coords.lng)
    this.nativeMap.panTo(center)
  }

  private handleAccessibilityElement(nodes: any = []) {
    for (let index = 0; index < nodes.length; index++) {
      const element = nodes[index]

      if (!element.setAttribute) {
        continue
      }

      element.setAttribute('aria-hidden', 'true')
      element.setAttribute('tabindex', -1)
      element.setAttribute('disabled', true)

      // recursive handle child elements
      if (element.childNodes) {
        this.handleAccessibilityElement(element.childNodes)
      }
    }
  }

  /**
   * Set location to the current user coords
   */
  locateUser() {
    // TODO - extract the specific breakdown location logic to be in the breakdown-location-step component
    //   Emit an event when locateUser gets clicked.
    this.store$.dispatch(
      setBreakdownLocationRequest({
        payload: this.userLocation,
        meta: { locationType: LOCATION_TYPE.GPS_LOCATION },
      })
    )

    // center user coords
    this.centerToCoords(this.userLocation)
    const lat = this.userLocation.lat
    const lng = this.userLocation.lng

    // notify location changed
    this.mapClick.emit({
      lat,
      lng,
    })
    this.taggingService.setClickEvent(
      events.location.LOCATE_ME,
      events.location.LOCATION_PAGE_TYPE
    )
    this.handleDragEndEvent()
  }

}
