import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { PrivacyPolicyService } from '../../../shared/services/privacy-policy.service'
import { TermsConditionsService } from '../../../shared/services/terms-conditions.service'
import { AAAStore } from '../../../store/root-reducer'
import { ConfigService } from '../../config/config.service'
import { RapService } from '../../rap/rap.service'
import { AdobeMemberValidationService } from '../../tagging/adobe/member-validation-adobe.service'
import { TaggingService } from '../../tagging/tagging.service'
import { CredentialsManagementService } from '../credential-management/credential-management.service'
import { AbstractComponent } from '../../../shared/abstract.component'
import { RecaptchaComponent, ReCaptchaV3Service } from 'ng-recaptcha'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { membershipMask, phoneMask } from './member-credentials.constants'
import { FormGroupState } from 'ngrx-forms'
import { MembershipNumberAuthFormState, NameAuthFormState } from '../../ui/forms/forms.reducer'
import { selectAuthMethod, selectAuthState, selectIsAgent, selectIsSecure } from '../auth.selectors'
import { selectSearchMembersResults } from '../../member/member.selectors'
import { filter, map } from 'rxjs/operators'
import { selectAuthForm, selectNameAuthForm } from '../../ui/forms/forms.selectors'
import { AuthMethods, BaseAuthRequestParams } from '../auth.types'
import {
  selectDefaultZipCode,
  selectMockCaptcha,
  selectCaptchaVersion,
  selectCaptchaModalOpen,
} from '../../ui/ui.selectors'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import {
  AUTH,
  AUTH_NAME_SEARCH,
  AUTH_RESET,
  notifyAuthFailure,
  requestAuth,
  setAuthMethod,
  setClickedUseAnotherForm
} from '../auth.actions'
import { MEMBER_BASIC_INFO, MEMBER_INFO, MEMBERS_SEARCH, searchMembersRequest } from '../../member/member.actions'
import { CALL_STATUS } from '../../dashboard/calls-statuses/call-status.actions'
import { AuthState } from '../auth.reducer'
import { buildTitle } from '../../../shared/utils/title'
import { TITLE_SIGN_IN } from '../../constants/shared.constants'
import {
  animateSplashscreenHide,
  closeDialog,
  openMessageDialog,
  setCaptchaModalOpen,
  setCaptchaVersion,
  setWatchRecaptchaPosition
} from '../../ui/ui.actions'
import { MessageDialogTypes, PromptDialogTypes } from '../../ui/ui.types'
import events from '../../tagging/events'
import { is16DigitNumberString } from '../../../shared/utils/membership-number'
import auth from '../../tagging/events/auth'
import { AdobeEventTypes, MemberValidationType } from '../../tagging/tagging.types'
import { isMobileApp } from '../../../shared/utils/app-detect'
import { CaptchaVersion } from '../../member/member.types'

@Component({
  selector: 'app-member-credentials',
  templateUrl: './member-credentials.component.html',
  styleUrls: ['./member-credentials.component.scss'],
})
export class MemberCredentialsComponent
  extends AbstractComponent
  implements OnInit, OnDestroy {
  @Input() displayCaptcha
  @Input() tabIndexPosition = 0

  @ViewChild('dialogContent', { static: true }) dialogContent: TemplateRef<any>
  @ViewChild('captchaElement') captchaElement: RecaptchaComponent

  authParams = new BehaviorSubject<any>({
    accessToken: null,
    memId: '',
    ets: '',
    isSecure: false,
  })
  captchaToken: string
  captchaError = false
  siteKey = this.configService.getConfig().googleCaptchaKey
  mask = membershipMask
  phoneNumberMask = phoneMask

  authForm: FormGroupState<MembershipNumberAuthFormState> = null
  nameAuthForm: FormGroupState<NameAuthFormState> = null
  allowNameAuth: boolean

  isAgent = false
  streetForm: any = {
    value: '',
  }

  isNameFormActive = false
  isMembershipNumberFormActive = false
  isPasswordCredentialSupported = false

  rememberMe = false
  isSecure$ = this.store$.pipe(
    select(selectIsSecure)
  )

  searchResults$ = this.store$.pipe(
    select(selectSearchMembersResults),
    filter((searchItems) => searchItems?.members?.length >= 1)
  )

  authForm$: Observable<FormGroupState<MembershipNumberAuthFormState>> =
    this.store$.pipe(select(selectAuthForm))
  nameAuthForm$: Observable<FormGroupState<NameAuthFormState>> =
    this.store$.pipe(select(selectNameAuthForm))
  allowNameAuth$: Observable<AuthMethods> = this.store$.pipe(
    select(selectAuthMethod)
  )

  defaultUserZipCode$: Observable<string> = this.store$.pipe(
    select(selectDefaultZipCode)
  )
  isSigningIn$: Observable<boolean> = combineLatest([
    this.store$.pipe(select(selectIsLoading(AUTH.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBER_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBER_BASIC_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBERS_SEARCH.ACTION))),
    this.store$.pipe(select(selectIsLoading(CALL_STATUS.ACTION))),
    this.store$.pipe(select(selectIsLoading(AUTH_NAME_SEARCH.ACTION))),
  ]).pipe(map((areActionsLoading) => areActionsLoading.some(Boolean)))
  authState$: Observable<[AuthState, boolean]> = combineLatest([
    this.store$.pipe(select(selectAuthState)),
    this.isSigningIn$,
  ])
  isAgent$: Observable<boolean> = this.store$.pipe(select(selectIsAgent))
  isResetNeeded$: Observable<boolean> = this.actions$.pipe(
    ofType<ReturnType<typeof notifyAuthFailure>>(AUTH.FAILURE, AUTH_RESET),
    map(() => true)
  )

  mockCaptcha$ = this.store$.pipe(
    select(selectMockCaptcha)
  )
  mockCaptcha = false

  captchaModalOpen$ = this.store$.pipe(
    select(selectCaptchaModalOpen)
  )

  captchaVersion$ = this.store$.pipe(
    select(selectCaptchaVersion)
  )
  captchaVersion: CaptchaVersion

  hideCatchaInPage = false

  authMethod
  isAuthMethodChangeClicked = false
  isErrorBoxDisplayed = false

  get formState(): FormGroupState<any> {
    if (this.allowNameAuth) {
      return this.nameAuthForm
    }
    return this.authForm
  }

  get controls(): any {
    return this.formState && this.formState.controls
  }

  constructor(
    protected actions$: Actions,
    protected store$: Store<AAAStore>,
    protected taggingService: TaggingService,
    protected adobeMemberValidationService: AdobeMemberValidationService,
    public dialog: MatDialog,
    protected changeDetector: ChangeDetectorRef,
    protected titleService: Title,
    protected rapService: RapService,
    protected credentialsManager: CredentialsManagementService,
    protected privacyPolicy: PrivacyPolicyService,
    protected termsConditionsService: TermsConditionsService,
    protected configService: ConfigService,
    protected recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super()
    this.titleService.setTitle(buildTitle(TITLE_SIGN_IN(), this.rapService.isRapUser()))
  }

  ngOnDestroy() {
    this.store$.dispatch(
      closeDialog({
        payload: { type: PromptDialogTypes.AUTH_PHONE_NUMBER_DIALOG }
      })
    )
  }

  ngOnInit() {
    this.subscriptions.push(
      // TODO investigate if it's still required
      this.authState$.subscribe(([authState]) => {
        this.authParams.next(authState)
      }),
      // TODO move to effects (unrelated to forms and captcha)
      this.isSecure$.pipe(filter((isSecure) => !isSecure)).subscribe((_) => {
        this.store$.dispatch(animateSplashscreenHide())
      }),
      this.authForm$.subscribe((form) => {
        this.authForm = form
      }),
      this.nameAuthForm$.subscribe((form) => {
        this.nameAuthForm = form
      }),
      this.defaultUserZipCode$.subscribe((zipCode) => {
        ;(this.authForm.controls.zipCode as any).value =
          zipCode || (null as any)
        ;(this.nameAuthForm.controls.zipCode as any).value =
          zipCode || (null as any)
      }),
      this.isAgent$.subscribe((agent) => {
        this.isAgent = agent
      }),
      this.isResetNeeded$
        .pipe(filter((isResetNeeded) => isResetNeeded))
        .subscribe(() => {
          this.hideCatchaInPage = false
        }),
      this.allowNameAuth$.subscribe((authMethod) => {
        this.allowNameAuth = authMethod === AuthMethods.MEMBER_NAME
        this.isNameFormActive = authMethod === AuthMethods.MEMBER_NAME
        this.isMembershipNumberFormActive = authMethod === AuthMethods.MEMBERSHIP_NUMBER
        this.isAuthMethodChangeClicked = this.authMethod && this.authMethod !== authMethod
        this.authMethod = authMethod
        this.sendEvent(authMethod)
      }),
      this.mockCaptcha$.subscribe((mockCaptcha) => {
        this.mockCaptcha = mockCaptcha
      }),
      this.captchaVersion$.subscribe((version) => {
        this.captchaVersion =  version
      }),
    )

    this.rememberMe = window.localStorage.getItem('remember-credentials') === 'true'
    this.isPasswordCredentialSupported = this.credentialsManager.isSupported()
    if (this.rememberMe && this.isPasswordCredentialSupported) {
      this.credentialsManager?.getCredentials()?.then((credentials) => {
        if (credentials?.firstName) {
          this.setMemberNameForm(credentials.firstName, credentials.lastName, credentials.zipCode)
        } else if (credentials?.memberNumber) {
          this.setMembershipNumberForm(credentials.memberNumber, credentials.zipCode)
        }
      })
    }
    this.watchRecaptchaPosition()
  }

  openPrivacyPolicy() {
    this.privacyPolicy.open()
  }

  openTerms() {
    this.termsConditionsService.open()
  }

  openCannotRemember($event) {
    $event.stopPropagation()
    $event.preventDefault()

    this.taggingService.setClickEvent(
      events.auth.LOST_MEMBERSHIP_CLICK,
      events.auth.PAGE_TYPE
    )

    this.store$.dispatch(
      openMessageDialog({
        payload: {
          type: MessageDialogTypes.CANT_REMEMBER_MY_ID,
        },
      })
    )
  }

  openNotMember($event) {
    $event?.stopPropagation()
    $event?.preventDefault()

    this.taggingService.setClickEvent(
      events.auth.NOT_A_MEMBER_CLICK,
      events.auth.PAGE_TYPE
    )
    this.store$.dispatch(
      openMessageDialog({
        payload: {
          type: MessageDialogTypes.NOT_MEMBER,
        },
      })
    )
  }

  doAuth(captchaToken: string) {
    const firstName = this.formState.value.firstName
    const lastName = this.formState.value.lastName
    const isMembershipNumberInNames = is16DigitNumberString(firstName) || is16DigitNumberString(lastName)
    const memberNumber = !isMembershipNumberInNames ?
      this.formState.value.membershipNumber && this.formState.value.membershipNumber.replace(/\s/g, '') :
      (is16DigitNumberString(firstName) ? firstName : lastName)
    const captchaVersion = this.captchaVersion

    if (this.allowNameAuth && !isMembershipNumberInNames) {
      this.searchForMembers(captchaToken, captchaVersion)
    } else {
      const { method } = this.authParams.getValue()
      this.signOn({ captchaToken, captchaVersion, method, firstName, lastName, memberNumber, isMembershipNumberInNames })
    }
    this.stopCaptchaWatcher()
  }

  handleSubmit() {
    if (this.mockCaptcha) {
      this.captchaToken = 'DUMMY_CAPTCHA_TOKEN'
    }
    if (!this.captchaElement) {
      if (this.captchaVersion === CaptchaVersion.V3 && !this.mockCaptcha) {
        this.recaptchaV3Service.execute('login').subscribe((token) => {
          this.doAuth(token)
        }, (error) => {
          this.store$.dispatch(setCaptchaVersion({ payload: CaptchaVersion.V2 }))
        })
      } else {
        this.taggingService.setAutomatedEvent(auth.CAPTCHA_VALIDATION_SUCCESS, auth.PAGE_TYPE)
        this.doAuth(null)
      }
      return
    }
    if (this.captchaElement) {
      if (!this.captchaToken) {
        this.handleCaptchaFailure()
        return
      }

      this.captchaError = false
      this.doAuth(this.captchaToken)
      this.captchaToken = null
      return
    }
  }

  handleCaptchaModalDismiss() {
    this.store$.dispatch(setCaptchaModalOpen({ payload: false }))
    this.hideCatchaInPage = false
  }

  handleCaptchaSuccess(captchaToken: string, triggerAuth = false) {
    this.taggingService.setAutomatedEvent(auth.CAPTCHA_VALIDATION_SUCCESS, auth.PAGE_TYPE)
    this.captchaError = false
    this.stopCaptchaWatcher()
    if (triggerAuth) {
      this.hideCatchaInPage = true
      this.doAuth(captchaToken)
      this.store$.dispatch(setCaptchaModalOpen({ payload: false }))
    } else {
      this.captchaToken = captchaToken
    }
  }

  handleCaptchaFailure() {
    this.taggingService.setAutomatedEvent(auth.CAPTCHA_VALIDATION_FAILURE, auth.PAGE_TYPE)
    this.captchaError = true
  }

  signOn({ captchaToken = null, captchaVersion = null, isMembershipNumberInNames = false, firstName, lastName, memberNumber }: BaseAuthRequestParams) {
    const zipCode = this.formState.value.zipCode

    if (
      Boolean(zipCode) &&
      (Boolean(memberNumber) || (Boolean(firstName) && Boolean(lastName)))
    ) {
      this.taggingService.setClickEvent(
        events.auth.FORM_MEMBERSHIP_AUTH_REQUEST,
        events.auth.PAGE_TYPE
      )
      this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION, MemberValidationType.MEMBER_NUMBER, {
        membership_number: memberNumber,
      })

      const authMethod = this.allowNameAuth && !isMembershipNumberInNames
        ? AuthMethods.MEMBER_NAME
        : AuthMethods.MEMBERSHIP_NUMBER
      if (isMembershipNumberInNames) {
        this.setMembershipNumberForm(memberNumber, zipCode)
      }
      const identifier = this.allowNameAuth && !isMembershipNumberInNames
        ? { firstName, lastName }
        : { memberNumber }
      const token = captchaToken !== null ? { captchaToken } : {}

      const payload = {
        zipCode: this.formState.value.zipCode,
        isSecure: false,
        method: authMethod,
        isAgent: this.isAgent,
        ...identifier,
        ...token,
        ...captchaVersion ? { captchaVersion } : {},
        rememberMe: this.rememberMe,
      }
      this.store$.dispatch(
        requestAuth({
          payload,
        })
      )
    }
  }

  useMemberNumber($event?) {
    if ($event) {
      $event.stopPropagation()
      $event.preventDefault()
    }
    this.isNameFormActive = false
    this.isMembershipNumberFormActive = true
    this.isAuthMethodChangeClicked = true

    this.store$.dispatch(setClickedUseAnotherForm())
    this.store$.dispatch(setAuthMethod({ payload: AuthMethods.MEMBERSHIP_NUMBER }))

    this.taggingService.setClickEvent(
      events.auth.USE_FORM_MEMBERSHIP_CLICK,
      events.auth.PAGE_TYPE
    )
    this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_TOGGLE, MemberValidationType.MEMBER_NUMBER)
  }

  useMemberName($event?) {
    if ($event) {
      $event.stopPropagation()
      $event.preventDefault()
    }

    this.isNameFormActive = true
    this.isMembershipNumberFormActive = false

    this.store$.dispatch(setClickedUseAnotherForm())
    this.store$.dispatch(setAuthMethod({ payload: AuthMethods.MEMBER_NAME }))
    this.isAuthMethodChangeClicked = true

    this.taggingService.setClickEvent(
      events.auth.USE_FORM_NAME_CLICK,
      events.auth.PAGE_TYPE
    )
    this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_TOGGLE, MemberValidationType.MEMBER_NAME)
  }

  watchRecaptchaPosition() {
    this.store$.dispatch(
      setWatchRecaptchaPosition({ payload: true })
    )
  }

  stopCaptchaWatcher() {
    this.store$.dispatch(
      setWatchRecaptchaPosition({ payload: false })
    )
  }

  /**
   * dispatch action to request members info
   *
   * @param captchaToken
   * @param captchaVersion
   */
  searchForMembers(captchaToken = null, captchaVersion?: CaptchaVersion) {
    this.taggingService.setClickEvent(
      events.auth.FORM_NAME_AUTH_REQUEST,
      events.auth.PAGE_TYPE
    )
    this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION, MemberValidationType.MEMBER_NAME)

    const firstName = this.formState.value.firstName
    const lastName = this.formState.value.lastName
    const zipCode = this.formState.value.zipCode.trim()
    const payload = {
      captchaToken,
      zipCode,
      firstName,
      lastName,
      rememberMe: this.rememberMe,
      ...captchaVersion ? { captchaVersion } : {},
    }

    this.store$.dispatch(searchMembersRequest({ payload }))
  }

  isWebApp() {
    return !isMobileApp()
  }

  setHasError(error) {
    this.captchaElement?.reset()
    this.isErrorBoxDisplayed = error
    this.changeDetector.detectChanges()
  }

  useMemberNameClicked(event) {
    this.useMemberName(event);
    this.isAuthMethodChangeClicked = true;
    this.isErrorBoxDisplayed = false
  }

  useMembershipNumberClicked(event) {
    this.useMemberNumber(event);
    this.isAuthMethodChangeClicked = true;
    this.isErrorBoxDisplayed = false
  }

  handleRememberMe(event) {
    this.rememberMe = event.target.checked
  }

  setMembershipNumberForm(memberNumber, zipCode) {
    this.store$.dispatch({
      controlId: 'form.auth.membershipNumber',
      value: memberNumber,
      type: 'ngrx/forms/SET_VALUE'
    })
    this.store$.dispatch({
      controlId: 'form.auth.zipCode',
      value: zipCode,
      type: 'ngrx/forms/SET_VALUE'
    })
  }

  sendEvent(authMethod: AuthMethods) {
    let pageName = authMethod.toString()
    let action = authMethod.toString()
    let adobeValidationType
    if (AuthMethods.MEMBERSHIP_NUMBER === authMethod
      || AuthMethods.AAA_TOKEN === authMethod
      || AuthMethods.AAA_NATIONAL === authMethod
      || AuthMethods.ENCRYPTED_MEMBER_NUMBER === authMethod) {
      pageName = events.auth.PAGE_NAME_MEMBERSHIP_NUMBER
      action = events.auth.FORM_MEMBERSHIP_AUTH_PROMPT
      adobeValidationType = MemberValidationType.MEMBER_NUMBER
    } else if (AuthMethods.MEMBER_NAME === authMethod) {
      pageName = events.auth.PAGE_NAME_LOGIN_LITE
      action = events.auth.FORM_NAME_AUTH_PROMPT
      adobeValidationType = MemberValidationType.MEMBER_NAME
    }
    this.taggingService.setPageLoadEvent({ pageType: events.auth.PAGE_TYPE, pageName })
    this.taggingService.setAutomatedEvent(action, events.auth.PAGE_TYPE)
    this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_PROMPT, adobeValidationType)
  }

  setMemberNameForm(firstName, lastName, zipCode) {
    this.store$.dispatch({
      controlId: 'form.nameauth.firstName',
      value: firstName,
      type: 'ngrx/forms/SET_VALUE'
    })
    this.store$.dispatch({
      controlId: 'form.nameauth.lastName',
      value: lastName,
      type: 'ngrx/forms/SET_VALUE'
    })
    this.store$.dispatch({
      controlId: 'form.nameauth.zipCode',
      value: zipCode,
      type: 'ngrx/forms/SET_VALUE'
    })
  }
}
