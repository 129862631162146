import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import { EffectsModule } from '@ngrx/effects'
import { ErrorHandler, NgModule } from '@angular/core'
import { State, StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { environment } from '../environments/environment'

import {
  AarEffects,
  AdvisoriesEffects,
  AuthEffects,
  CallEffects,
  CallsStatusesEffect,
  ClubEffects,
  ConfigEffects,
  IssueEffects,
  LocationEffects,
  MemberEffects,
  metaReducers,
  ModeConfigurationEffects,
  QuotesEffects,
  reducers,
  ReviewEffects,
  RouteDistanceEffects,
  ServicingClubEffects,
  SessionEffects,
  SharedEffects,
  SharedModule,
  SubmitEffects,
  TaggingEffects,
  UIEffects,
  VehicleEffects,
  WizardEffects,
} from '@aaa-mobile/drrweb-lib'

import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module'
import { NotFoundComponent } from './components'
import { AppComponent } from './app.component'
import { I18nModule } from '../../libs/drrweb-lib/src/lib/shared/i18n/i18n.module'
import { IonicModule } from '@ionic/angular'
import { provideIonicAngular } from '@ionic/angular/standalone'

const providersArray = []

export class DefaultErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    throw err
  }
}

providersArray.push({
  provide: ErrorHandler,
  useClass: DefaultErrorHandler,
})

providersArray.push({ provide: State, useValue: null })

providersArray.push(I18nModule.setLocale(), I18nModule.setLocaleId())

providersArray.push(provideIonicAngular())

export const APP_ID = 'drr-v2'

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: APP_ID }),
    BrowserAnimationsModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateImmutability: false,
      },
    }),
    EffectsModule.forRoot([
      SessionEffects,
      AarEffects,
      ReviewEffects,
      LocationEffects,
      AuthEffects,
      ModeConfigurationEffects,
      IssueEffects,
      MemberEffects,
      SubmitEffects,
      SharedEffects,
      TaggingEffects,
      VehicleEffects,
      WizardEffects,
      CallEffects,
      CallsStatusesEffect,
      ClubEffects,
      UIEffects,
      QuotesEffects,
      ServicingClubEffects,
      ConfigEffects,
      AdvisoriesEffects,
      RouteDistanceEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 30,
      logOnly: environment.log,
      connectInZone: true
    }),
    SharedModule.forRoot({
      drrBaseHref: '/',
      aaaConfigurationServicesUrl: environment.aaaConfigurationServicesUrl,
      aaaQuoteServicesUrl: environment.aaaQuoteServicesUrl,
      additionalCookieDomain: environment.additionalCookieDomain,
      association: environment.association,
      baseURL: environment.baseURL,
      clientId: environment.clientId,
      configTools: environment.configTools,
      description: environment.description,
      displayVersion: environment.displayVersion,
      errorReportingID: environment.errorReportingID,
      errorReportingSource: environment.errorReportingSource,
      errorReportingToken: environment.errorReportingToken,
      errorReportingURL: environment.errorReportingURL,
      googleCaptchaKey: environment.googleCaptchaKey,
      googleCaptchaV3Key: environment.googleCaptchaV3Key,
      googleMapsApiKey: environment.googleMapsApiKey,
      rapConfigurationBaseUrl: environment.rapConfigurationBaseUrl,
      rapGoogleMapsApiKey: environment.rapGoogleMapsApiKey,
      thirdPartyCookiesUrl: environment.thirdPartyCookiesUrl,
      unsecureClub: String(environment.unsecureClub),
      nativeAppView: environment.nativeAppView,
      homeBackButtonRedirectTo: environment.homeBackButtonRedirectTo,
      optimize: environment.optimize,
      log: environment.log,
      openAARsURL: environment.openAARsURL,
      ipInterrogateURL: environment.ipInterrogateURL,
      hotjar: environment.hotjar,
      orgId: environment.orgId,
      datastreamId: environment.datastreamId
    }),
    IonicModule.forRoot(),
  ],
  exports: [AppRoutingModule],
  providers: [...providersArray],
  bootstrap: [AppComponent],
})
export class AppModule {
}
