<aaa-modal
  i18n-title
  [title]="'Location Details'"
  i18n-subtitle
  [subtitle]="'Optional'"
  [isOpen]="isOpen"
  [initialBreakpoint]="1"
  (aaaModalDidDismiss)="dismissModal()"
  [breakpoints]="[0, 1]"
  contentMode="fit"
  >
  <div class="ion-margin-top ion-margin-bottom">
    <div class="selected-address">
      <app-location-address [address]="address"></app-location-address>
    </div>
    <drr-location-highway-exit />

    <aaa-button appCtaClickEvent="Adjust Location On Map" class="adjust-location" variant="secondary" expand="block" (click)="adjustLocation()" i18n>
      <aaa-icon name="current-location"></aaa-icon>
      Adjust Location On Map
    </aaa-button>

    <ion-list>
      <ion-item *ngFor="let item of optionTemplates">
        <drr-checkbox [(ngModel)]="item.checked" (change)="handleChange(item)"><aaa-text color="dark" i18n>{{item.name}}</aaa-text></drr-checkbox>
      </ion-item>
    </ion-list>

    <aaa-button class="next-button" expand="block" (click)="next()" i18n>
      Next
      <aaa-icon name="arrow-right"></aaa-icon>
    </aaa-button>

  </div>
</aaa-modal>
