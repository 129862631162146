import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { GooglePlacesService } from '../../../ui/autocomplete/google-places/google-places.service'
import { GenericCoordinates, LastSearchLocation, USE_CURRENT_LOCATION } from '../../../location/location.types'
import { LocationAddress } from '../breakdown-location.types'
import { AARData } from '../../../location/aar/aar.types'
import { Observable, of, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators'
import { GoogleMapsConfig } from '../../../../google-maps-config'
import { UIService } from '../../../ui/ui.service'
import { IonSearchbar } from '@ionic/angular'
import { AbstractComponent } from '../../../../shared/abstract.component'
import { AdobeEventService } from '../../../tagging/adobe/event-adobe.service';
import { AdobeEventTypes } from '../../../tagging/tagging.types';
import events from '../../../tagging/events';


@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss'],
})
export class LocationSearchComponent extends AbstractComponent implements OnInit, AfterViewInit {
  @ViewChild('search') search: IonSearchbar
  @ViewChild('searchContainer') searchContainer: ElementRef<HTMLDivElement>

  @Input()
  location: GenericCoordinates
  @Input()
  selectedAddress: string
  @Input()
  gpsAvailable = false
  @Input()
  minLengthToFetch = 3
  @Input()
  showUseCurrentLocation = false
  @Input()
  lastSearchLocation: LastSearchLocation | undefined
  @Input()
  suggestedDestination: AARData | undefined
  @Input()
  placeholder: string | undefined
  @Input()
  useShadow = true
  @Input()
  hasBackButton = false
  @Input()
  rap = false

  @Output()
  locationSelected = new EventEmitter<LocationAddress>()
  @Output()
  suggestedDestinationClick = new EventEmitter<AARData>()

  @Output()
  useCurrentLocation = new EventEmitter<void>()

  @Output()
  inputClear = new EventEmitter<void>()

  ionicMode = 'ios'

  showOptions = false
  searchFailed = false
  searchTerms: string
  searchTermsSubject = new Subject<string>()
  searchElTopPosition: number
  searchElTopPosition$: MutationObserver
  viewportHeight$: Observable<number> = this.uiService.viewportHeight$
  viewportHeight: number
  filteredItems: any = []

  get listMaxHeight() {
    const defaultPaddingTop = 75
    return this.viewportHeight - this.searchElTopPosition - defaultPaddingTop
  }

  constructor(
    private googlePlacesService: GooglePlacesService,
    private googleMapsConfig: GoogleMapsConfig,
    private uiService: UIService,
    private adobeEventService: AdobeEventService,
  ) {
    super()
  }

  ngOnInit() {
    this.subscriptions.push(
      this.viewportHeight$.subscribe(height => this.viewportHeight = height),
      this.googleMapsConfig.obsCurrentApiStatus.pipe(
        filter(status => status === true),
        tap(() => this.setupSearch())
      ).subscribe(),
    )
  }

  ngAfterViewInit() {
    this.searchElTopPosition$ = new MutationObserver(
      (mutations: MutationRecord[]) => {
        this.searchElTopPosition = (mutations[0].target as HTMLElement).getBoundingClientRect().top
      }
    )
    this.searchElTopPosition$.observe(this.searchContainer.nativeElement, { childList: true, subtree: true, })
  }

  focus() {
    setTimeout(() => {
      this.search.setFocus()
    }, 1000)
    this.searchElTopPosition$.disconnect()
  }

  locationSelect(item: LocationAddress) {
    if (item.custom_value === USE_CURRENT_LOCATION) {
      if (this.gpsAvailable) {
        this.useCurrentLocation.emit()
        this.showOptions = false
      }
    } else {
      this.locationSelected.emit(item)
      this.showOptions = false
    }
  }

  suggestedDestinationClicked() {
    this.suggestedDestinationClick.emit(this.suggestedDestination)
    this.showOptions = false
  }

  clearSearch() {
    this.searchFailed = false
    this.searchTerms = ''
    this.showOptions = false
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.shared.LOCATION_SEARCH_CLEAR
    })
    this.inputClear.emit()
  }

  private setupSearch() {
    this.subscriptions.push(this.searchTermsSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => {
        this.searchTerms = term
        return this.googlePlacesService.search(term, this.location).pipe(
          catchError(() => {
            this.searchFailed = true;
            this.filteredItems = [];
            return of(null);
          })
        )
      }),
      filter(data => !!data)
    ).subscribe(data => this.assembleItems(data)))
  }

  searchbarInput(input: CustomEvent) {
    const value = input.detail.value
    this.showOptions = value.length >= this.minLengthToFetch;
    this.searchFailed = false
    if (this.showOptions) {
      this.searchTermsSubject.next(value)
    } else if(value.length === 0) {
      this.clearSearch()
    }
  }

  assembleItems(items) {
    const customItems = []
    if (this.showUseCurrentLocation) {
      customItems.push({
        icon: this.gpsAvailable ? 'location' : 'location-off',
        main_text: $localize`Use current position`,
        custom_value: USE_CURRENT_LOCATION,
      })
    }
    if (this.lastSearchLocation) {
      customItems.push({
        ...this.lastSearchLocation,
        icon: 'clock',
      })
    }
    this.filteredItems = [...customItems, ...items]
  }
}
