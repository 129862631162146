import { Injectable } from '@angular/core'

import { AuthBypassRequestParams, AuthNoRoutingResponse, RedirectParams } from './auth.types';
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { setAuth } from './auth.actions'
import { AuthEffects } from './auth.effects'
import { map, take } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { selectAuthParams, selectPreviousPageNavigation } from '../ui/ui.selectors'
import { PageNavigationEvent } from '../ui/ui.types'
import { parseUrl } from '../../shared/utils/query-params'
import { AuthSecurityWrapperService } from './auth-security-wrapper/auth-security-wrapper.service'
import { setCookie } from '../../shared/utils/cookies'

@Injectable({
  providedIn: 'root',
})
export class LibAuthService {
  constructor(
    private store$: Store<AAAStore>,
    private authEffects: AuthEffects,
    private authService: AuthSecurityWrapperService
  ) {
  }

  setAppId(appId: string): void {
    setCookie('AAA_AppId', appId.toUpperCase())
  }

  setRedesign(_: boolean) {
    // drop this line from the aaa mobile project: this.drrUserInit.setRedesign(true); from DrrLibInitService
    console.warn('setRedesign method is deprecated')
  }

  logout(): void {
    this.authService.logout(false)
  }

  authBypass(
    {
      token,
      club
    }: AuthBypassRequestParams
  ): Observable<AuthNoRoutingResponse> {
    this.store$.dispatch(setAuth({
      payload: {
        club,
        access_token: token,
        no_routing: true
      }
    }))
    return this.authEffects.libAuthResponse$.asObservable().pipe(take(1))
  }

  getLastRoute = (): Observable<RedirectParams> =>
    this.store$.pipe(select(selectPreviousPageNavigation))
      .pipe(
        map((pageNavigation: PageNavigationEvent) => parseUrl(pageNavigation.url))
      )

  getAuthResponse = (): Observable<AuthNoRoutingResponse> =>
    this.store$.pipe(select(selectAuthParams))
      .pipe(
        map((authParams) => authParams)
      )

}
