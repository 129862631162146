import { Collection } from '../../shared/types'
import { VehicleData } from '../member/member.types'

export type VehicleYears = Collection<'years', string>
export type VehicleMakes = Collection<'makes', string>
export type VehicleModels = Collection<'models', string>

export interface GetVehicleModelsParams {
  year: number
  make: string
}

export interface EditVehicleParams {
  vehicle: VehicleData
  isActive: boolean
  section: string
}

export interface VehicleEditMetadata {
  isActive?: boolean
  completionUrl?: string
  step?: string
}

export enum VehicleDriveTypes {
  ALL_WHEEL_DRIVE = 'AWD',
  FOUR_WHEEL_DRIVE = '4WD',
  REAR_WHEEL_DRIVE = 'RWD',
  FRONT_WHEEL_DRIVE = 'FWD'
}

export enum AddVehicleModes {
  MAKE_MODEL = 'make-model',
  PLATE_VIN = 'plate-vin'
}

export interface ModelType {
  vehicleType: string,
  vehicleTypeName: string,
  driveType: VehicleDriveTypes,
  driveTypeName: VehicleDriveTypes,
  bodyTypeName: string
}

export interface ModelTypesResponse {
  types: Array<ModelType>
}

export interface Make {
  label: string,
  icon?: string
}

export enum GENERIC_MAKES {
  MOTORHOME_RV = 'motorhome-rv',
  MOTORCYCLE = 'motorcycle',
}

export interface MakeModels {
  [make: string]: string[]
}

export interface Vehicle {
  vin?: string,
  year?: number | string,
  make?: string,
  model?: string,
  tag?: string,
  state?: string,
  trim?: string,
  fuelType?: string,
  driveType?: string,
  vehicleType?: string,
  color?: string,
  images?: VehicleImages
}

export interface PlateToVinResponse {
  vehicles: Vehicle[]
}

export interface VehicleRequestQueryParams {
  showTypicalVehicles: boolean,
  showMotorcycles: boolean,
  showMotorhomes: boolean
}

export interface VehicleImageLinks {
  full?: string
  thumbnail?: string
}
export interface VehicleImages {
  front?: VehicleImageLinks
}

export interface VehicleImagesResponse {
  vehicleImages: VehicleImages
}

export interface VehicleColor {
  name: string;
  hex: string;
  shade: string;
  shadeAlpha: number;
}

export interface Selection {
  value: string,
  other: boolean,
}
