import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { from, Observable } from 'rxjs'
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { ErrorReportingService } from '../../../shared/services/error-reporting.service'
import { DestinationType, IndexedCollection, PayloadedAction } from '../../../shared/types'
import { AAAStore } from '../../../store/root-reducer'
import { selectMaxTowMileLimit } from '../../auth/auth.selectors'
import {
  selectActiveCallStatus,
  selectIsDestinationSearchRequired
} from '../../dashboard/calls-statuses/call-status.selectors'
import { selectActivePaceSetterCode } from '../../issue/issue.selectors'
import { ASSIGN_EXISTING_VEHICLE, SET_VEHICLE } from '../../member/member.actions'
import { selectMemberActiveVehicle, selectMemberData } from '../../member/member.selectors'
import { RapService } from '../../rap/rap.service'
import { NAVIGATE_NEXT_STEP } from '../../wizard/wizard.actions'
import { SET_LOCATION_CLUB } from '../location.actions'
import { selectBreakdownLocation, selectBreakdownLocationCoordinates, selectCountryClub } from '../location.selectors'
import { GenericCoordinates } from '../location.types'
import { aarAppendLocationMarker, LocationUtils } from '../location.utils'
import { NON_AAR_TOWING_NAMES, resetTowDestination, setAARAddress } from '../tow-location/tow-location.actions'
import {
  AAR_LOAD,
  completeAarLoad,
  notifyAarLoadFailure,
  notifyHomeCenterFailure,
  requestAars,
  requestSearchArea,
  SEARCH_AREA,
  SET_BREAKDOWN_LOCATION_CENTER,
  SET_HOME_CENTER,
  setAarPreview,
  setSuggestedShop
} from './aar.actions'
import { TOW_DESTINATION_LIMIT } from './aar.reducer'
import { selectAARParams, selectCurrentVehicleSlug, selectDestinationType, selectNearbyAARs, } from './aar.selectors'
import { AarService } from './aar.service'
import { AARData, AARRequestParams, SearchType } from './aar.types'
import { areAddressesEqual, filterAarByServiceOffers } from './aar.utils'
import { cancelStepsAhead, openMessageDialog } from '../../ui/ui.actions'
import { MessageDialogTypes } from '../../ui/ui.types';
import { GoogleGeocodeService } from '../google-geocode/google-geocode.service'
import { selectTowLocationPreview } from '../tow-location/tow-location.selectors'
import { FUEL_TYPE_ELECTRIC } from '../../vehicle/vehicle.utils';

@Injectable()
export class AarEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private _aarService: AarService,
    private errorReportingService: ErrorReportingService,
    private rapService: RapService,
    private locationUtils: LocationUtils,
    private _geocodeService: GoogleGeocodeService,
  ) {}

  setHomeAsTowDestinationCenter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_HOME_CENTER.REQUEST),
      withLatestFrom(
        this.store$.pipe(select(selectMemberData)),
        this.store$.pipe(select(selectIsDestinationSearchRequired)),
      ),
      filter(([_, memberData, isDestinationSearchRequired]) => Boolean(isDestinationSearchRequired && memberData?.basicAddress && memberData?.city && memberData?.stateProvince && memberData?.postalCode)),
      switchMap(([_, member, _2]) => {
        const address = `${member.basicAddress}, ${member.city}, ${member.stateProvince} ${member.postalCode}`
        return from(this._geocodeService.getLocationFromAddress(address)).pipe(
          switchMap((addresses) => {
            const location = addresses[0].geometry.location
            const coords: GenericCoordinates = { latitude: location.lat(), longitude: location.lng() }
            return [
              requestSearchArea({
                payload: {
                  center: coords
                }
              })
            ];
          }),
        )
      }),
      catchError((error) =>
        this.errorReportingService.notifyErrorObservable(
          error,
          notifyHomeCenterFailure
        )
      )
    ),
  )

  refreshAars$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SET_LOCATION_CLUB.SUCCESS,
        ASSIGN_EXISTING_VEHICLE,
        SET_VEHICLE,
        NAVIGATE_NEXT_STEP,
        SET_BREAKDOWN_LOCATION_CENTER
      ),
      tap(() => this.store$.dispatch(cancelStepsAhead())),
      withLatestFrom(
        this.store$.pipe(select(selectIsDestinationSearchRequired)),
        this.store$.pipe(select(selectBreakdownLocation)),
        this.store$.pipe(select(selectMemberActiveVehicle)),
        this.store$.pipe(select(selectCurrentVehicleSlug)),
        this.store$.pipe(select(selectActiveCallStatus)),
      ),
      switchMap(([_, isDestinationSearchRequired, breakdownLocation, activeVehicle, vehicleSlug, activeCallStatus]) => {
        const actions = []
        if (activeCallStatus === null) {
          actions.push(resetTowDestination())
        }
        if (isDestinationSearchRequired) {
          actions.push(requestAars({
            payload: {
              latitude: breakdownLocation.latitude,
              longitude: breakdownLocation.longitude,
              make: activeVehicle.make,
              vehicleSlug,
              searchType: SearchType.BREAKDOWN_LOCATION,
            },
          }))
        }
        return actions
      })
    )
  )

  searchArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof requestSearchArea>>(SEARCH_AREA.REQUEST),
      withLatestFrom(
        this.store$.pipe(select(selectMemberActiveVehicle)),
        this.store$.pipe(select(selectCurrentVehicleSlug))
      ),
      switchMap(([action, activeVehicle, vehicleSlug]) => [
        setAarPreview({ payload: { id: null } }),
        requestAars({
          payload: {
            latitude: action.payload.center.latitude,
            longitude: action.payload.center.longitude,
            make: activeVehicle.make,
            vehicleSlug,
            searchType: SearchType.CUSTOM_LOCATION,
          },
        })
      ])
    )
  )

  loadAars$ = createEffect(
    (): Observable<
      | ReturnType<typeof completeAarLoad>
      | ReturnType<typeof notifyAarLoadFailure>
    > =>
      this.actions$.pipe(
        ofType<ReturnType<typeof requestAars>>(AAR_LOAD.REQUEST),
        filter((action) => Boolean(action.payload)),
        withLatestFrom(
          this.store$.pipe(select(selectActivePaceSetterCode)),
          this.store$.pipe(select(selectDestinationType)),
          this.store$.pipe(select(selectMaxTowMileLimit)),
          this.store$.pipe(select(selectBreakdownLocationCoordinates)),
          this.store$.pipe(select(selectTowLocationPreview)),
          this.store$.pipe(select(selectCountryClub)),
          this.store$.pipe(select(selectMemberActiveVehicle)),
        ),
        switchMap(([action, activePaceCode, destinationType, maxTowMileLimit, breakdownLocation, towLocation, country, vehicle]) =>
          from(this._aarService.getAARs({
            ...action.payload,
            ...(maxTowMileLimit ? { radius: maxTowMileLimit } : {}),
            limit: TOW_DESTINATION_LIMIT,
            country,
            ...(vehicle?.fuelType === FUEL_TYPE_ELECTRIC ? { type: 'EV_DEALER' } : {}),
          }, destinationType)).pipe(
            map((aars) => filterAarByServiceOffers(activePaceCode, aars, this.rapService.isRapUser())),
            tap((aars) => this.recalculateDistanceTo(aars, breakdownLocation)),
            switchMap((aars) => {
              const actions: PayloadedAction[] = [
                completeAarLoad({
                  payload: {
                    [action.payload.vehicleSlug]: aars,
                  },
                })
              ]
              if (towLocation?.location?.name === NON_AAR_TOWING_NAMES.CUSTOM) {
                const aarMatch: AARData = Object.values(aars).find(aar => areAddressesEqual(aar.address.addressLine, `${towLocation.location.streetNumber} ${towLocation.location.streetName}`))
                const shop = aarAppendLocationMarker(aarMatch)
                if (aarMatch && shop) {
                  actions.push(setAarPreview({ payload: { id: aarMatch.id } }))
                  actions.push(setAARAddress({
                    payload: shop,
                  }))
                }
              }
              return actions
            }
            )
          )
        ),
        catchError((error) =>
          this.errorReportingService.notifyErrorObservable(
            error,
            notifyAarLoadFailure
          )
        )
      )
  )

  handleEvStationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AAR_LOAD.SUCCESS),
      withLatestFrom(this.store$.pipe(select(selectDestinationType))),
      filter(([action, destinationType]: [PayloadedAction, DestinationType]) =>
        DestinationType.EV_STATION === destinationType && !Object.keys(Object.values(action.payload)[0]).length
      ),
      map(() => openMessageDialog({
        payload: {
          type: MessageDialogTypes.CUSTOM_PHONE,
          content: $localize`No EV charging locations were found. To continue with your service request, please contact us at`,
        },
      }))
    )
  )

  handleSetSuggestedShop$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof completeAarLoad>>(AAR_LOAD.SUCCESS),
      withLatestFrom(
        this.store$.pipe(select(selectDestinationType)),
        this.store$.pipe(select(selectNearbyAARs)),
        this.store$.pipe(select(selectAARParams)),
      ),
      filter(([_, destinationType, aars, aarParams]: [PayloadedAction, DestinationType, AARData[], AARRequestParams]) =>
        SearchType.BREAKDOWN_LOCATION === aarParams?.searchType
        && DestinationType.AAR === destinationType
        && aars.length > 0
      ),
      map(([_, _2, aars]: [PayloadedAction, DestinationType, AARData[], AARRequestParams]) => setSuggestedShop({
        payload: aars[0]
      }))
    )
  )

  recalculateDistanceTo(aars: IndexedCollection<AARData>, breakdownLocationCoordinates: GenericCoordinates) {
    Object.keys(aars).forEach((id) => aars[id].distanceTo = this.locationUtils.haversineMiles(aars[id], breakdownLocationCoordinates))
  }
}
